<template>
  <v-container class="py-9">
    <shop-featured-product class="mb-5" :products="shopDetails.featured_products.data" :is-loading="loading"/>

    <shop-banner-section-one class="mb-5" :banners="shopDetails.banner_section_one" :is-loading="loading"/>

    <shop-new-products class="mb-5" :products="shopDetails.new_arrival_products.data" :is-loading="loading"/>

    <!-- 隐藏优惠券 -->
    <!-- <shop-coupon-section class="mb-5" :coupons="shopDetails.latest_coupons.data" :is-loading="loading" /> -->

    <shop-banner-section-two class="mb-5" :banners="shopDetails.banner_section_two" :is-loading="loading"/>

    <shop-best-rated-products class="mb-5" :products="shopDetails.best_rated_products.data" :is-loading="loading" />

    <shop-banner-section-three class="mb-5" :banners="shopDetails.banner_section_three" :is-loading="loading"/>

    <shop-best-selling-products class="mb-5" :products="shopDetails.best_selling_products.data" :is-loading="loading"/>

    <shop-banner-section-two class="mb-5" :banners="shopDetails.banner_section_four" :is-loading="loading"/>

    <router-link class="border rounded border-primary bg-soft-primary d-flex justify-center align-center text-reset pa-9" :to="{ name: 'ShopProducts', params: {slug: $route.params.slug}}">
      <span class="fw-700 fs-21 text-primary">{{ $t('view_all_products_of_this_shop') }}</span>
      <i class="la la-arrow-right la-2x ms-2 text-primary"></i>
    </router-link>
  </v-container>
</template>

<script>
import ShopBannerSectionThree from '../../components/shop/ShopBannerSectionThree'
import ShopBannerSectionTwo from '../../components/shop/ShopBannerSectionTwo'
import ShopBannerSectionOne from '../../components/shop/ShopBannerSectionOne'
import ShopFeaturedProduct from '../../components/shop/ShopFeaturedProduct'
import ShopNewProducts from '../../components/shop/ShopNewProducts'
import ShopBestRatedProducts from '../../components/shop/ShopBestRatedProducts'
import ShopBestSellingProducts from '../../components/shop/ShopBestSellingProducts'
import ShopCouponSection from '../../components/shop/ShopCouponSection'
export default {
    components: {
        ShopFeaturedProduct,
        ShopBannerSectionOne,
        ShopBannerSectionTwo,
        ShopBannerSectionThree,
        ShopNewProducts,
        ShopBestRatedProducts,
        ShopBestSellingProducts,
        ShopCouponSection
    },
    data: () => ({
        loading: true,
        shopDetails: {
            featured_products:{
                data: []
            },
            new_arrival_products:{
                data: []
            },
            best_rated_products:{
                data: []
            },
            best_selling_products:{
                data: []
            },
            latest_coupons:{
                data: [{},{},{}]
            },
            banner_section_one: [{},{},{}],
            banner_section_two: [{}],
            banner_section_three: [{},{}],
            banner_section_four: [{}],
        },
        carouselOption: {
            slidesPerView: 1,
        },
    }),
    async created(){
        console.log('shop detailssssssssssss')

        const res = await this.call_api('get', `shop/${this.$route.params.slug}/home`)
        if (res.data.success) {
            this.shopDetails = res.data.data
            this.loading = false
        }
    }
}
</script>